.dashboard-layout-wrapper {
    .logo {
        float: left;
        a {
            color: #fff;
            margin-left: 20px
        }
        i {
            color: #fff;
            font-size: 25px;
            padding-right: 15px;
            top: 22px;
            position: absolute;
            left: 15.5px;
        }
        cursor: pointer;
        img {
            width: 100px;
            height: auto;
        }
    }
    background: #fff
}

.main-menu.ant-menu {
    border-right: none !important;
    .ant-menu-sub {
        background-color: #F9FAFC;
    }
}

.main-drawler {
    .ant-drawer-body {
        padding: 0
    }
    .ant-drawer-close {
        display: none
    }
    .o2o-pin-sider {
        position: absolute;
        right: 20px;
        top: 10px;
        cursor: pointer;
    }
}

.ant-popover-inner-content {
    // padding: 0
}

.popover-o2o-avatar {
    min-width: 300px;
    .ant-divider {
        margin: 10px 0
    }
}

.o2o-main-sider {
    .ant-layout-sider-children {
        border-right: 1px solid #e8e8e8;
    }
}

.logo-img {
    width: 100px;
    height: auto;
    margin-bottom: 15px;
}