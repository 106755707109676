///Custom paging 
.o2o-custom-paging .ant-pagination-prev,
.o2o-custom-paging .ant-pagination-next,
.o2o-custom-paging .ant-pagination-jump-prev,
.o2o-custom-paging .ant-pagination-jump-next,
.o2o-custom-paging .ant-pagination-item {
    min-width: 45px !important;
    height: 45px !important;
    line-height: 45px !important;
    border-radius: 0;
}
.o2o-custom-paging .ant-pagination-item-1 {
    border-right-width: 1px !important;
    margin-left: -9px !important;
}
.o2o-custom-paging .ant-pagination-next {
    margin-left: -8px;
}
.o2o-custom-paging .ant-pagination-item {
    margin-left: -8px;
    border-right-width: 0;
    &.ant-pagination-item-active {
        border-right-width: 1px;
    }
    &.ant-pagination-item-before-jump-next {
        border-right-width: 1px;
    }
    &:hover {
        border-right-width: 1px;
    }
}