.drowdown-menu-right {
    button.ant-btn {
        background: transparent;
        border: none;
        color: #fff;
        box-shadow: none;
        &:hover {
            background: transparent;
            border: none;
            opacity: 0.5;
        }
    }
}

.landing {
    .landing-form {
        margin: 0 auto;
    }
    .logo {
        width: 120px;
        height: 31px;
        margin: 16px 28px 16px 0;
        float: left;
        width: 100px;
        height: auto;
        float: left;
        img {
            width: 100px;
            height: auto;
            float: left;
        }
    }
}