.dashboard-layout-wrapper{
    header{
        transition: transform 0.4s;
    }
    .scroll-up{
        
        header{
            transform: none;
        }
    }
    .scroll-down{
        header{
            transform: translate3d(0, -100%, 0);
           
        }
    }
}